import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import vuetify from './plugins/vuetify'
import '@/assets/styles/global.css'
import "@/scss/styles.scss";
import Carousel3d from 'vue-carousel-3d';
import store from "./store";
import router from './router';

import { InitializeGoogleMapApi } from "./plugins/google";

InitializeGoogleMapApi(process.env.VUE_APP_GOOGLE_API_KEY).then(() => {
  Vue.config.productionTip = false
  Vue.use(VueGtag, {
    config: { id: "G-Y56GQ0WJD8" },
    appName: 'Flety sin web',
    pageTrackerScreenviewEnabled: true
  });
  Vue.use(Carousel3d);
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});
