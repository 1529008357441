import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Launch/LaunchPage.vue"),
  },
  {
    path: "/links",
    name: "links",
    component: () => import("@/views/Links/LinksPage.vue"),
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Launch/LaunchPage.vue"),
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: () => import("@/views/Quiz/QuizView.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
