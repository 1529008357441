const trackEvents = (eventProps) => {
  const { gtag, action, category, label, value } = eventProps;
  if (gtag) {
    gtag.event(action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  } else {
    console.error("gtag no está disponible.");
  }
}

export default trackEvents;
